import React, { useEffect } from "react";
import { navigate } from "gatsby";

import { Confirmation } from "../components/common";

import Auth from "../components/auth";
import ConfirmationImage from "../assets/images/confirmations/user.svg";

function ConfirmToCreateAccountPage() {
  useEffect(() => {
    if (typeof window === "undefined" || !window?.history?.state?.email) {
      navigate("/create-account");
    }
  });

  return (
    <Auth title="Signup">
      <Confirmation
        subTitle="Email Sent"
        title="Please Check Your Email to Complete The Account Creation"
        description={
          <>
            It will arrive in a moment. Open the email, click on the{" "}
            <strong>Create Your Account button</strong>. If you use gmail,
            please find the email in promotions or updates tab. Also, check the
            spam folder if you did not get the email.
          </>
        }
        image={ConfirmationImage}
        instruction="Showing how to complete your account creation"
      />
    </Auth>
  );
}

export default ConfirmToCreateAccountPage;
